import Header from "src/pages/homePage/header/header";
import styles from "./tradePage.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { Helmet } from "react-helmet";
import Top from "./top/top";
import ContentSection from "./contentSection/contentSection";
import easyToBuildImg from "src/assets/whoWeServe/trades/easyToBuild.png";
import integrationAndNotification from "src/assets/whoWeServe/trades/integrationAndNotification.png";
import easilyCollectInformation from "src/assets/whoWeServe/trades/easilyCollectInformation.png";
import increaseLeadQuality from "src/assets/whoWeServe/trades/increaseLeadQuality.png";
import gearIcon from "src/assets/whoWeServe/trades/gear.svg";
import sky from "src/assets/whoWeServe/trades/sky.svg";
import server from "src/assets/whoWeServe/trades/server.svg";
import funds from "src/assets/whoWeServe/trades/funds.svg";
import { useEffect } from "react";
import { ITradePage } from "../interfaces/interfaces";

const TradePage: React.FC<ITradePage> = ({
  tradeName,
  url,
  embededFormUrl,
}: ITradePage) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | {tradeName}</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link rel="canonical" href={`https://leadquiz.com${url}`} />
        <meta property="og:title" content={tradeName} />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://leadquiz.com${url}`} />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <Top tradeName={tradeName} embededFormUrl={embededFormUrl} />
      <div className={styles.gradient} />
      <div className={styles.wrapper}>
        <div className={styles.border}>
          <div className={styles.contentSection}>
            <ContentSection
              icon={gearIcon}
              title="Easy to Build"
              img={easyToBuildImg}
              description={`Create your LeadQuiz with no coding knowledge and easily collect your customers' job requirements and information. Our intuitive, user-friendly interface offers a variety of customization options, allowing you to tailor your LeadQuiz  to perfectly suit your ${tradeName.toLocaleLowerCase()} business.`}
            />
            <ContentSection
              isReversed
              icon={sky}
              title="Integration & Notification"
              img={integrationAndNotification}
              description="Get instant lead notifications via email and/or SMS, ensuring you're always in the loop. With our seamless Zapier integration, our software automatically syncs lead data with your preferred CRM, including popular platforms like LeadConnector, Jobber, BuilderPrime, DripJobs, HubSpot, and many more. No manual data entry, no hassle—just streamlined automation."
            />
            <ContentSection
              icon={server}
              title="Easily Collect Information"
              img={easilyCollectInformation}
              description={`Our form builder offers a variety of field types—such as text boxes, checkboxes, and dropdown menus—making it easy to collect your ${tradeName.toLocaleLowerCase()} customers' job information and requirements.`}
            />
            <ContentSection
              isReversed
              icon={funds}
              title="Increase Lead Quality"
              img={increaseLeadQuality}
              description={`Our forms enhance lead quality by validating the information submitted, including contact details like phone numbers and emails. Unlike traditional Facebook forms, our forms require more intent from customers before submission, ensuring that the leads you receive are ready to purchase your services. This thorough validation and commitment result in higher-quality leads for your ${tradeName.toLocaleLowerCase()} business.`}
            />
          </div>
        </div>
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TradePage;
