import React, { useRef } from "react";
import styles from "./textArea.module.scss";
import { ITextArea } from "../interfaces/interfaces";

const TextArea: React.FC<ITextArea> = ({
  placeholder,
  value,
  onChange,
  label,
  color,
}: ITextArea) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  return (
    <div className={styles.wrapper}>
      {label && value && value !== "" && (
        <p className={styles.inputLabel}>{label}</p>
      )}
      <textarea
        ref={textareaRef}
        value={value}
        onChange={(e) => {
          onChange?.(e);
          adjustHeight();
        }}
        placeholder={placeholder}
        style={{ borderColor: color }}
      />
    </div>
  );
};

export default TextArea;
