import React, { useEffect, useState } from "react";
import styles from "./formView.module.scss";
import { useDispatch, useSelector } from "react-redux";
import FormViewFooter from "./formViewFooter/formViewFooter";
import QuestionPage from "./questionPage/questionPage";
import { IQuestion } from "../formBuilder/interfaces/interfaces";
import { RotatingLines } from "react-loader-spinner";
import {
  clearUserAnswersAC,
  setCurrentFormPageAC,
  setQuestionsAC,
} from "src/store/actions";
import { getFormApi, getPublishedFormApi } from "src/_utils/api/api";
import { Helmet } from "react-helmet";
import HowShallWeContactYou from "./howShallWeContactYou/howShallWeContactYou";
import { useNavigate, useParams } from "react-router-dom";
import PublishFormModal from "./publishFormModal/publishFormModal";
import ROUTES from "src/_utils/routes/routes";
import FormNavigation from "./formNavigation/formNavigation";
import ProgressBar from "src/_elements/progressBar/progressBar";
import COLORS from "src/_utils/colors/colors";

const FormView = () => {
  const questionsList = useSelector((state: any) => state.questionsList);
  const currentIndex = useSelector((state: any) => state.currentFormPage);
  const currentConditionalQuestionsIDs = useSelector(
    (state: any) => state.currentConditionalQuestionsIDs
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const subdomain = window.location.hostname.split(".")[0];

  const [isLoading, setIsLoading] = useState(true);
  const [isShowEndingPage, setIsShowEndingPage] = useState(false);
  const [answerIndex, setAnswerIndex] = useState(0);
  const [currentFormId, setCurrentFormId] = useState("");
  const [isFormPublished, setIsFormPublished] = useState(false);
  const [isShowPublishModal, setIsShowPublishModal] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isMaxSubmissionsReached, setIsMaxSubmissionsReached] = useState(false);
  const [businessName, setBusinessName] = useState("[your business name]");
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [facebookPixelId, setFacebookPixelId] = useState("");
  const [googleAdsId, setgoogleAdsId] = useState("");
  const [conversionLabel, setConversionLabel] = useState("");
  const [formColorTheme, setFormColorTheme] = useState(COLORS.BLUE);
  const [isHidePoweredLabel, setIsHidePoweredLabel] = useState(false);
  const [isHideLogo, setIsHideLogo] = useState(false);
  const [isHideFooter, setIsHideFooter] = useState(false);

  const [isInsideIframe, setIsInsideIframe] = useState(false);

  useEffect(() => {
    setIsInsideIframe(window.self !== window.top);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    if (id) {
      getFormApi(id)
        .then((res) => {
          dispatch(setQuestionsAC(res.data.data.formData.formsData));
          setCurrentFormId(res.data.data.form.id);
          setIsFormPublished(res.data.data.form.isPublished);
          setFormColorTheme(res.data.data.primaryColor);
          setIsHidePoweredLabel(res.data.data.hidePoweredLabel);
          setIsHideLogo(res.data.data.hideLogo);
          setIsHideFooter(res.data.data.hideFooter);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else if (subdomain) {
      getPublishedFormApi(subdomain).then((res) => {
        setCurrentFormId(res.data.data.id);
        dispatch(setQuestionsAC(res.data.data.formsData));
        setIsMaxSubmissionsReached(res.data.data.maxSubmissionReached);
        setBusinessName(res.data.data.businessData.businessName);
        setPrivacyPolicyLink(res.data.data.businessData.privacyPolicyLink);
        setFacebookPixelId(res.data.data?.facebookPixelData?.facebookPixelId);
        setgoogleAdsId(res.data.data.googleAdsData?.conversionId);
        setConversionLabel(res.data.data.googleAdsData?.conversionLabel);
        setFormColorTheme(res.data.data.primaryColor);
        setIsHidePoweredLabel(res.data.data.hidePoweredLabel);
        setIsHideLogo(res.data.data.hideLogo);
        setIsHideFooter(res.data.data.hideFooter);
        setIsLoading(false);
      });
    }

    return () => {
      dispatch(setCurrentFormPageAC(0));
      dispatch(clearUserAnswersAC());
    };
  }, []);

  const isLastPage =
    currentIndex === questionsList?.questions?.length ||
    currentIndex >
      questionsList.questions?.filter(
        (el: IQuestion) => !currentConditionalQuestionsIDs?.includes(el.id)
      ).length;

  return (
    <>
      <div
        style={
          isInsideIframe
            ? { backgroundColor: "transparent" }
            : { backgroundColor: "#f9f9f9" }
        }
        className={styles.outerWrapper}
      >
        {isMaxSubmissionsReached && (
          <div className={styles.maxSubmissionsReachedErrWrap}>
            <p>Max submissions reached</p>
          </div>
        )}
        <Helmet>
          <title>LeadQuiz | Form</title>
        </Helmet>
        {!isFormPublished && !isLoading && id && (
          <div className={styles.publshMessage}>
            <p>
              You are currently in viewing mode. Publish the form so others can
              respond to it.
            </p>
            <button onClick={() => setIsShowPublishModal(true)}>Publish</button>
          </div>
        )}
        {!isMaxSubmissionsReached && !isHideLogo && (
          <img
            className={styles.companyLogo}
            src={questionsList?.logo}
            alt="company logo"
          />
        )}
        {!isMaxSubmissionsReached &&
          (!isLoading ? (
            <div className={styles.wrapper}>
              {questionsList.questions.map((el: IQuestion, index: number) => (
                <QuestionPage
                  key={index}
                  index={index}
                  element={el}
                  isVisible={index === currentIndex}
                  answerIndex={answerIndex}
                  selectedAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  facebookPixelId={facebookPixelId}
                  googleAdsId={googleAdsId}
                  conversionLabel={conversionLabel}
                  isShowEndingPage={isShowEndingPage}
                  formColorTheme={formColorTheme}
                />
              ))}
              {isLastPage && !isShowEndingPage && (
                <HowShallWeContactYou formColorTheme={formColorTheme} />
              )}
              <div className={styles.progressBarWrap}>
                <ProgressBar
                  currentStep={currentIndex}
                  totalFormPagesCount={
                    questionsList.questions.filter(
                      (el: IQuestion) =>
                        !currentConditionalQuestionsIDs?.includes(el.id)
                    ).length
                  }
                  color={formColorTheme}
                />
              </div>
              <FormNavigation
                content={questionsList.questions.filter(
                  (el: IQuestion) =>
                    !currentConditionalQuestionsIDs?.includes(el.id)
                )}
                isLastPage={isLastPage}
                setAnswerIndex={setAnswerIndex}
                selectedAnswer={selectedAnswer}
                setSelectedAnswer={setSelectedAnswer}
                formColorTheme={formColorTheme}
              />
              {isShowEndingPage && navigate(ROUTES.THANK_YOU)}
              {isHideFooter && !isShowEndingPage && !isLastPage ? (
                <></>
              ) : (
                !isShowEndingPage && (
                  <FormViewFooter
                    content={questionsList.questions.filter(
                      (el: IQuestion) =>
                        !currentConditionalQuestionsIDs?.includes(el.id)
                    )}
                    isLastPage={isLastPage}
                    setIsShowEndingPage={setIsShowEndingPage}
                    setAnswerIndex={setAnswerIndex}
                    currentFormId={currentFormId}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    businessName={businessName}
                    privacyPolicyLink={privacyPolicyLink}
                    formColorTheme={formColorTheme}
                    isHidePoweredLabel={isHidePoweredLabel}
                  />
                )
              )}
            </div>
          ) : (
            <RotatingLines
              visible={true}
              width="30"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="black"
            />
          ))}
      </div>
      <PublishFormModal
        isShowModal={isShowPublishModal}
        setIsShowModal={setIsShowPublishModal}
        formId={id}
      />
    </>
  );
};

export default FormView;
