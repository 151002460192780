import React, { useEffect } from "react";
import styles from "./chooseIsShowPowered.module.scss";
import ToggleSwitch from "src/_elements/toggleSwitch/toggleSwitch";
import { useDispatch, useSelector } from "react-redux";
import { setIsHideFooterAC, setIsHidePoweredLabelAC } from "src/store/actions";

const ChooseIsShowPowered: React.FC<any> = ({ isCanHidePoweredLabel }: any) => {
  const dispatch = useDispatch();

  const isHideFooter = useSelector((state: any) => state.isHideFooter);
  const isHidePoweredLabel = useSelector(
    (state: any) => state.isHidePoweredLabel
  );

  useEffect(() => {
    if (isHideFooter) {
      dispatch(setIsHidePoweredLabelAC(false));
    }
  }, [isHideFooter]);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h1>Footer customization</h1>
        <p>Customize your form footer</p>
        <div
          className={`${styles.content} ${
            isCanHidePoweredLabel
              ? styles.alignContentLeft
              : styles.alignContentCenter
          }`}
        >
          <div className={styles.item}>
            <ToggleSwitch
              toggleChange={() => {
                dispatch(setIsHideFooterAC(!isHideFooter));
              }}
              value={isHideFooter}
            />
            <p>Hide footer</p>
          </div>
          {isCanHidePoweredLabel && (
            <div className={styles.item}>
              <ToggleSwitch
                toggleChange={() => {
                  dispatch(setIsHidePoweredLabelAC(!isHidePoweredLabel));
                }}
                value={isHidePoweredLabel}
                disabled={isHideFooter}
              />
              <p>Hide "Powered by LeadQuiz" message</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseIsShowPowered;
