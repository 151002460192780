import { useDispatch } from "react-redux";
import styles from "./top.module.scss";
import devices from "src/assets/homePage/devices.png";
import {
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import { ITop } from "../../interfaces/interfaces";

const Top: React.FC<ITop> = ({ tradeName, embededFormUrl }: ITop) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.textContent}>
          <h1 className={styles.title}>
            LeadQuiz is the perfect tool for{" "}
            <span>{tradeName.toLowerCase()}</span> businesses looking to
            streamline their lead collection process.
          </h1>
          <p className={styles.subtitle}>
            Capture valuable customer insights with ease and boost engagement
            through customizable, user-friendly forms.
          </p>
          <button
            onClick={
              token
                ? () => dispatch(setIsShowPricingModalAC(true))
                : () => dispatch(setIsShowRegisterModalAC(true))
            }
            className={styles.btn}
          >
            Try it for yourself
          </button>
        </div>
        <div className={styles.topImgWrap}>
          <img src={devices} alt="devices" />
        </div>
      </div>
      <div className={styles.formPreview}>
        <p>Try It For Yourself</p>
        <iframe
          src={embededFormUrl}
          width="100%"
          height="500px350px"
          scrolling="no"
          data-cookieconsent="ignore"
          id="leadquiz_form"
          className={styles.iframe}
        ></iframe>
      </div>
    </div>
  );
};

export default Top;
