import React from "react";
import styles from "./toggleSwitch.module.scss";
import { IToggleSwitch } from "../interfaces/interfaces";

const ToggleSwitch: React.FC<IToggleSwitch> = ({
  toggleChange,
  value,
  disabled,
}: IToggleSwitch) => {
  const handleToggle = () => {
    toggleChange(!value);
  };

  return (
    <label className={styles["toggle-switch"]}>
      <input
        type="checkbox"
        checked={value}
        onChange={handleToggle}
        disabled={disabled}
      />
      <span className={disabled ? styles.disabledSlider : styles.slider}></span>
    </label>
  );
};

export default ToggleSwitch;
