import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteFormApi,
  getFormsApi,
  isPaidCallAvailableApi,
  postFormApi,
  unpublishFormApi,
} from "src/_utils/api/api";
import ROUTES from "src/_utils/routes/routes";
import {
  setCompanyLogoAC,
  setQuestionsAC,
  setSelectedFormTemplateIdAC,
} from "src/store/actions";
import Header from "../homePage/header/header";
import FormSubmissionEmailIntegration from "../settingPage/formSubmissionEmailIntegration/formSubmissionEmailIntegration";
import ZapierEmbedElement from "../settingPage/zapierEmbedElement/zapierEmbedElement";
import FormItemPreview from "./formItemPreview/formItemPreview";
import styles from "./forms.module.scss";
import FormTemplatesModal from "./formTemplatesModal/formTemplatesModal";
import AlertMessage from "src/_elements/alertMessage/alertMessage";

const Forms = () => {
  const [formsList, setFormsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [zapierApiKeyCopyStatus, setZapierApiKeyCopyStatus] = useState("");
  const [isShowFormTemplatesModal, setIsShowFormTemplatesModal] =
    useState(false);
  const [isShowOnboardingAlert, setIsShowOnboardingAlert] = useState(false);

  const token = localStorage.getItem("token");

  const isShowOnboardingAlertMessage = sessionStorage.getItem(
    "isShowOnboardingAlertMessage"
  );

  const isUserPlanActive = useSelector((state: any) => state.isUserPlanActive);
  const isCurrentUserPlanLoading = useSelector(
    (state: any) => state.isCurrentUserPlanLoading
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormOpen = (el: any, logo: string) => {
    dispatch(setQuestionsAC(el.formData.formsData));
    dispatch(setCompanyLogoAC(logo));

    const subdomain = el.cname.subDomain;
    const newUrl = `https://${subdomain}.${process.env.REACT_APP_DOMAIN}`;

    window.location.href = newUrl;
  };

  const getForms = () => {
    getFormsApi()
      .then((res) => {
        setFormsList(res.data.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getForms();
  }, []);

  useEffect(() => {
    isPaidCallAvailableApi().then((res) => {
      if (
        res.data.data.available &&
        isShowOnboardingAlertMessage === "hidden"
      ) {
        setIsShowOnboardingAlert(false);
      } else if (
        !res.data.data.available &&
        isShowOnboardingAlertMessage !== "hidden"
      ) {
        setIsShowOnboardingAlert(true);
      }
    });
  }, [isShowOnboardingAlertMessage]);

  useEffect(() => {
    if (!token || !isCurrentUserPlanLoading) {
      if (!isUserPlanActive) {
        navigate(ROUTES.HOME);
      }
    }
  }, [token, isUserPlanActive, isCurrentUserPlanLoading]);

  useEffect(() => {
    if (zapierApiKeyCopyStatus !== "") {
      setTimeout(() => {
        setZapierApiKeyCopyStatus("");
      }, 2000);
    }
  }, [zapierApiKeyCopyStatus]);

  const handleDeleteForm = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsLoading(true);

    deleteFormApi(el.form?.id)
      .then(() => {
        getForms();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleDuplicateForm = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsLoading(true);

    let payload;

    if (el.primaryColor) {
      payload = {
        name: el.form.name + " (copy)",
        primaryColor: el.primaryColor,
        formsData: el.formData.formsData,
      };
    } else {
      payload = {
        name: el.form.name + " (copy)",
        formsData: el.formData.formsData,
      };
    }

    postFormApi(payload)
      .then(() => {
        getForms();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleUnpublishForm = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsLoading(true);

    const payload = {
      formId: el.form?.id,
    };

    unpublishFormApi(payload)
      .then(() => {
        getForms();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const sortedFormsList = formsList.sort(
    //@ts-ignore
    (a, b) => new Date(b.form.createdAt) - new Date(a.form.createdAt)
  );

  const formsListArray =
    sortedFormsList &&
    sortedFormsList.map((el: any, index: number) => (
      <FormItemPreview
        key={index}
        el={el}
        handleFormOpen={handleFormOpen}
        handleDeleteForm={handleDeleteForm}
        handleDuplicateForm={handleDuplicateForm}
        handleUnpublishForm={handleUnpublishForm}
        getForms={getForms}
      />
    ));

  const handleCreateNewForm = () => {
    dispatch(setSelectedFormTemplateIdAC(""));
    setIsShowFormTemplatesModal(true);
  };

  const handleAlertClose = () => {
    sessionStorage.setItem("isShowOnboardingAlertMessage", "hidden");
    setIsShowOnboardingAlert(false);
  };

  return (
    <>
      <div className={styles.outerWrapper}>
        <Helmet>
          <title>LeadQuiz | My forms</title>
        </Helmet>
        <Header />
        <div className={styles.wrapper}>
          {isShowOnboardingAlert && (
            <div className={styles.alertWrapper}>
              <AlertMessage
                message="Get the Most Out of LeadQuiz with Expert Onboarding!"
                handleAction={ROUTES.PRO_ONBOARDING_SETUP}
                onClose={handleAlertClose}
              />
            </div>
          )}
          {!isCurrentUserPlanLoading && (
            <div className={styles.formsWrapper}>
              <button
                onClick={handleCreateNewForm}
                className={styles.newFormButton}
              >
                <p>New form</p>
              </button>
              {!isLoading ? (
                formsListArray
              ) : (
                <RotatingLines
                  visible={true}
                  width="30"
                  strokeWidth="5"
                  animationDuration="0.75"
                  strokeColor="black"
                />
              )}
            </div>
          )}
        </div>
        {formsList[0] && (
          <FormSubmissionEmailIntegration formsArray={formsList} />
        )}
        <ZapierEmbedElement />
      </div>
      <FormTemplatesModal
        isShow={isShowFormTemplatesModal}
        setIsShow={setIsShowFormTemplatesModal}
      />
    </>
  );
};

export default Forms;
